import * as React from 'react';

import Seo from '../components/seo';
import Footer from '../components/Footer';
import NotFound from '../assets/image/404.png';
import { Button } from 'antd';
import { navigate } from 'gatsby';
import '../assets/scss/404.scss';
const NotFoundPage = () => {
  const gotoHome = () => {
    navigate('/');
  };
  const gotoBack = () => {
    window.history.go(-1);
  };
  return (
    <React.Fragment>
      <div className="not-found-wrapper">
        <div className="container">
          <Seo title="404: Not found" />
          <div className="content">
            <img src={NotFound} alt="404" width="520" height="300" />
            <p className="text">访问的页面已丢失</p>
            <div className="btns">
              <Button className="btn light" onClick={gotoHome}>
                返回首页
              </Button>
              <Button className="btn" onClick={gotoBack}>
                返回上一页
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default NotFoundPage;
